import React from 'react';

import '../styles/Components.css';

import CoverImage from '../assets/design-image-min2.webp';

import Socials from '../components/Socials';
import Slideshow from '../components/Slideshow';

const Home = () => {

    return (

        <div className='components'>
            <div className='container'>

            <h1>Welcome to Helping Hand Designs</h1>

            <Socials />

            <div className='oval-container'>
                <img src={CoverImage} alt='Arizona Black Walnut Countertops' /> 
            </div>

            <Slideshow />

            </div>

            <div className="container-small">
                <p>
                    We offer a wide range of custom woodworking options, including live edge products, epoxy products, furniture, and wood countertops, 
                    over at our <a href="https://crystalwoodelements.com" target="_blank" rel="noreferrer">Crystal Wood Elements</a>shop.
                </p>
            </div>
        </div>

    );

};

export default Home;
