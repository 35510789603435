import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';
import PropertyManagement from "../assets/property-management-min.webp";

const HandymanServices = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>Handyman Services</h1>

                <Socials />

                <div className="container-slim" >
                    <br /><br />
                    We offer a range of handyman services such as:<br /><br />

                    Furniture, IKEA, and Murphy Bed Assembly<br />
                    Painting<br />
                    Installation Services<br />
                    Grout &amp; Caulking<br />
                    Bathroom Vanities<br />
                    Light Masonry<br />
                    Cabinets: Installation, Repairs, and Hardware<br />
                    Cabinet Fronts - Custom Built<br />
                    Custom Mirror Frames<br />
                    Drywall<br />
                    Shelving Installation<br />
                    Garage Cabinets<br />
                    Organizational Services<br />
                    Light Electrical &amp; Plumbing<br />
                    Board and Batten/Wainscoating<br />
                    Chicken Coops, Animal Enclosures, and Accessories<br />
                    Shorten Barstool Legs (Standard Chair Height)<br />
                    Miscellaneous Services<br />
                </div>
                <br></br>
                <br></br>
                <div className="container-slim" >
                    <img src={PropertyManagement} alt="Property Management"/>
                </div>

            </div>
        </div>

    );

};

export default HandymanServices;
