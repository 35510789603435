import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';
import BeforeAfter from '../components/BeforeAfter'

const Home = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>Interior Design</h1>

                <Socials />

                <div className="protocol-container" >
                    We create designs that are inviting, comfortable and work well for everyday living.  Offering a specialty in design and the option for 
                    custom woodworking furniture, we integrate your personal touch into any space.  Elements of design helps to form the energy of your home 
                    and our company works closely with clients one on one to turn their ideas into reality.  We specialize in bringing in different arrays of 
                    color throughout our designs, all while keeping a very clean and fresh look to the space.<br /><br />  

                    We also offer the option to have custom woodworking pieces made personally for you and your space in our Crystal Wood Elements workshop.  
                    From live edge furniture and high end countertops to solid wood shelving and décor, we can build just about anything from scratch and tailored 
                    to your desired needs.  Check out our woodworking page at <a href='https://crystalwoodelements.com' target='blank'>crystalwoodelements.com</a>.

                </div>

                <div className='protocol-container'>
                    <br /><BeforeAfter />
                </div>

            </div>
        </div>

    );

};

export default Home;
