import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import NavbarComponent from './components/Navbar'
import FooterComponent from './components/Footer'

import Home from './routes/index';
import InteriorDesign from './routes/design';
import HandymanServices from './routes/handyman';
import PropertyMaintenanceServices from './routes/property-maintenance';
import Gallery from './routes/gallery';
import AboutUs from './routes/about';
import ContactUs from './routes/contact';
import Testimonials from './routes/testimonials';

import './styles/index.css'

function App() {

    return (

        <Router>
            <NavbarComponent />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/design' element={<InteriorDesign />} />
                <Route path='/handyman' element={<HandymanServices />} />
                <Route path='/property-maintenance' element={<PropertyMaintenanceServices />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/testimonials' element={<Testimonials />} />
            </Routes>
            <FooterComponent />
        </Router>
    
    )

}

export default App
