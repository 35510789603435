import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';
import PropertyManagement from "../assets/property-management-min.webp";

const PropertyManagementServices = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>Property Maintenance Services</h1>

                <Socials /><br></br>

                <div className="container-slim" >
                    <img src={PropertyManagement} alt="Property Management"/>
                </div>

            </div>
        </div>

    );

};

export default PropertyManagementServices;
