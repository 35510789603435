import React from 'react'
import {FaFacebook, FaInstagram} from 'react-icons/fa';

import '../styles/Socials.css'

const Socials = () => {

    return (

        <div className='socials'>
            <a href='https://facebook.com/HelpingHandDesignsAZ/' target='_blank' rel="noreferrer" alt="Helping Hand Designs Facebook Page"><FaFacebook className='icon' /></a>
            <a href='https://instagram.com/HelpingHandDesignsAZ/' target='_blank' rel="noreferrer" alt="Helping Hand Designs Instagram Page"><FaInstagram className='icon' /></a>
        </div>

    );

};

export default Socials;
