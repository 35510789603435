
import '../styles/ImageGrid.css';

/* Full Size Images for Link */
import BeforeAfter001 from "../assets/BeforeAndAfter/BEF-AFT-001.webp";
import BeforeAfter002 from "../assets/BeforeAndAfter/BEF-AFT-002.webp";
import BeforeAfter003 from "../assets/BeforeAndAfter/BEF-AFT-003.webp";
import BeforeAfter004 from "../assets/BeforeAndAfter/BEF-AFT-004.webp";
import BeforeAfter005 from "../assets/BeforeAndAfter/BEF-AFT-005.webp";
import BeforeAfter006 from "../assets/BeforeAndAfter/BEF-AFT-006.webp";
import BeforeAfter007 from "../assets/BeforeAndAfter/BEF-AFT-007.webp";
import BeforeAfter008 from "../assets/BeforeAndAfter/BEF-AFT-008.webp";
import BeforeAfter009 from "../assets/BeforeAndAfter/BEF-AFT-009.webp";
import BeforeAfter010 from "../assets/BeforeAndAfter/BEF-AFT-010.webp";

const BeforeAfter = () => {

    return (

        <div className="row"> 
            <div className="column-3">
                <a href={BeforeAfter001} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter001} alt="Before and After "/>
                </a>
                <a href={BeforeAfter002} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter002} alt="Before and After 2"/>
                </a>
                <a href={BeforeAfter003} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter003} alt="Before and After 3"/>
                </a>
                <a href={BeforeAfter004} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter004} alt="Before and After 4"/>
                </a>
            </div>

            <div className="column-3">
                <a href={BeforeAfter005} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter005} alt="Before and After 5"/>
                </a>
                <a href={BeforeAfter006} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter006} alt="Before and After 6"/>
                </a>
                <a href={BeforeAfter007} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter007} alt="Before and After 7"/>
                </a>
            </div>

            <div className="column-3">
                <a href={BeforeAfter008} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter008} alt="Before and After 8"/>
                </a>
                <a href={BeforeAfter009} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter009} alt="Before and After 9"/>
                </a>
                <a href={BeforeAfter010} target="_blank" rel="noreferrer">
                    <img src={BeforeAfter010} alt="Before and After 10"/>
                </a>
            </div>

            <div className="column">
            </div>
        </div>

    );

};

export default BeforeAfter;
