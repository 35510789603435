import React, {useState} from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'

import '../styles/Navbar.css'

function Navbar() {

    const [click, setClick] = useState(false);
    
    const handleClick = () => setClick(!click);

    return (
        <div className='navbar'>
            <div className='container'>
                <div></div>
                <a href='/'>
                    <div className='logo-container'>
                        <span className='logo-lettering'>HHDAZ</span>
                        <span className='logo-name'>Helping Hand Designs</span>
                    </div>
                </a>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='menu-item'><a href='/design'>Interior Design</a></li>
                    <li className='menu-item'><a href='/handyman'>Handyman Services</a></li>
                    <li className='menu-item'><a href='/property-maintenance'>Property Maintenance</a></li>
                    <li className='menu-item'><a href='/gallery'>Gallery</a></li>
                </ul>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={20} style={{color: '#333'}}/>) : (<FaBars size={20} style={{color: '#333'}}/>) }
                </div>
                <div></div>
            </div> 

        </div>
    )

}

export default Navbar
